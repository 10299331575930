import { MANAGE_POSTS_EVENT, DELETE_BLOG_EVENT } from '../constants/events';
import translation from './translation';

const { translate } = translation;
const pagesPanelUrl = 'https://editor.wix.com/communities-blog-pages-panel';

const getAppManifest = (sdkScriptSrc, appToken) => {
  const getPanelUrl = (slug) => `${pagesPanelUrl}/${slug}?wix-editor-script=${sdkScriptSrc}&token=${appToken}`;

  return {
    pages: {
      pageActions: {
        default: [
          'Pages_Actions_Page_Rename',
          {
            title: translate('app-manifest.delete-page'),
            event: DELETE_BLOG_EVENT,
            icon: 'deleteRadio',
          },
        ],
        postPage: [],
      },
      pageSettings: {
        default: [
          {
            title: translate('app-manifest.page-info'),
            type: 'page_info',
          },
          {
            title: translate('app-manifest.layout'),
            type: 'layout',
          },
          {
            title: translate('app-manifest.permissions'),
            type: 'permissions',
          },
          {
            title: translate('app-manifest.seo'),
            type: 'seo',
          },
        ],
        postPage: [
          {
            title: translate('app-manifest.page-info'),
            url: getPanelUrl('page-info'),
            type: 'page_info',
          },
          {
            title: translate('app-manifest.layout'),
            type: 'layout',
          },
          {
            title: translate('app-manifest.permissions'),
            type: 'permissions',
          },
          {
            title: translate('app-manifest.seo'),
            url: getPanelUrl('seo'),
            type: 'seo',
          },
        ],
      },
      applicationSettings: {
        default: {
          displayName: translate('app-manifest.blog-pages'),
        },
      },
      applicationActions: {
        default: {
          defaultValues: [
            {
              title: translate('app-manifest.manage-posts'),
              event: MANAGE_POSTS_EVENT,
              icon: 'settingsAction',
            },
            {
              title: translate('app-manifest.delete-blog'),
              event: DELETE_BLOG_EVENT,
              icon: 'deleteRadio',
            },
          ],
        },
      },
      pageDescriptors: {
        default: {
          icon: 'blogPageType',
        },
      },
    },
  };
};

export default getAppManifest;
